import { ConfigProvider } from "./ConfigContext";
import ConfigPageContent from "./ConfigPageContent";

function ConfigPage() {
    return (
        <ConfigProvider>
            <ConfigPageContent />
        </ConfigProvider>
    );
}

export default ConfigPage;
