import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import useConfigContext from "./useConfigContext";

const columns = [
    {
        field: "description",
        headerName: "Leírás",
        minWidth: 70,
        flex: 0.3,
    },
    {
        field: "value",
        headerName: "Érték",
        minWidth: 70,
        flex: 0.7,
    },
];

function ConfigTable() {
    const { configs, setSelectedConfig } = useConfigContext();

    function selectionModelChanged(ids) {
        const id = ids.length !== 0 ? ids[0] : null;
        setSelectedConfig(id);
    }

    return (
        configs && (
            <div style={{ height: 500, width: "100%" }}>
                <DataGrid
                    rows={configs}
                    columns={columns}
                    pageSize={10}
                    getRowId={(row) => row.key}
                    rowsPerPageOptions={[10]}
                    disableColumnSelector
                    components={{ Toolbar: GridToolbar }}
                    onSelectionModelChange={(ids) => selectionModelChanged(ids)}
                />
            </div>
        )
    );
}

export default ConfigTable;
