import useFullScreenProgressBarContext from "../components/common/fullscreenprogressbar/useFullScreenProgressBarContext";
import useGeneralErrorContext from "../components/common/generalerrorsnackbar/useGeneralErrorContext";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { EQUIPMENT_TYPE_RESTRICTION_URL } from "./Url";

export function useEquipmentTypeRestrictionApi() {
    const { setShowFullScreenProgressBar } = useFullScreenProgressBarContext();
    const { handleGeneralError } = useGeneralErrorContext();
    const axiosPrivate = useAxiosPrivate();

    async function getEquipmentTypeRestrictionsByType(
        equipmentTypeId,
        onSuccess
    ) {
        setShowFullScreenProgressBar(true);
        try {
            await axiosPrivate
                .get(
                    `${EQUIPMENT_TYPE_RESTRICTION_URL}/list/${equipmentTypeId}`,
                    {
                        withCredentials: true,
                    }
                )
                .then((response) => {
                    if (typeof onSuccess === "function") onSuccess(response);
                });
        } catch (error) {
            handleGeneralError(error);
        } finally {
            setShowFullScreenProgressBar(false);
        }
    }

    async function downloadLogo(restrictionId) {
        setShowFullScreenProgressBar(true);
        try {
            const response = await axiosPrivate.get(
                `${EQUIPMENT_TYPE_RESTRICTION_URL}/${restrictionId}/download`,
                {
                    responseType: "blob",
                    withCredentials: true,
                }
            );
            const href = URL.createObjectURL(response.data);
            const link = document.createElement("a");
            link.href = href;
            link.setAttribute("download", "logo.png");
            document.body.appendChild(link);
            link.click();

            // clean up "a" element & remove ObjectURL
            document.body.removeChild(link);
            URL.revokeObjectURL(href);
        } catch (error) {
            handleGeneralError(error);
        } finally {
            setShowFullScreenProgressBar(false);
        }
    }

    return { getEquipmentTypeRestrictionsByType, downloadLogo };
}
