import Login from "./components/login/Login";
import Layout from "./components/layout/Layout";
import { Routes, Route, useLocation } from "react-router-dom";
import RequireAuth from "./components/RequireAuth";
import Unauthorized from "./components/Unauthorized";
import DropBoxAuth from "./components/dropbox/DropBoxAuth";
import DropBoxCode from "./components/dropbox/DropBoxCode";
import PersistLogin from "./components/login/PersistLogin";
import AdminPage from "./components/admin/AdminPage";
import PasswordReset from "./components/passwordreset/PasswordReset";
import Dashboard from "./components/dashboard/Dashboard";
import AdminUserPage from "./components/admin/users/AdminUserPage";
import AdminTemplatePage from "./components/admin/templates/AdminTemplatePage";
import OperatorPage from "./components/basicdata/operator/OperatorPage";
import ManufacturerPage from "./components/basicdata/manufacturer/ManufacturerPage";
import CertificateIssuerPage from "./components/basicdata/certificateissuer/CertificateIssuerPage";
import LocationPage from "./components/basicdata/location/LocationPage";
import EquipmentCategoryPage from "./components/basicdata/equipmentcategory/EquipmentCategoryPage";
import EquipmentTypePage from "./components/basicdata/equipmenttype/EquipmentTypePage";
import EquipmentPage from "./components/basicdata/equipment/EquipmentPage";
import StandardPage from "./components/basicdata/standard/StandardPage";
import PlaygroundReport from "./components/report/PlaygroundReport";
import ReportListPage from "./components/report/list/ReportListPage";
import ForgotPassword from "./components/forgotpassword/ForgotPassword";
import { ROLE } from "./utils/Roles";
import InspectionTypePage from "./components/basicdata/inspectiontype/InspectionTypePage";
import EquipmentTypeAttributePage from "./components/basicdata/equipmentTypeAttributes/EquipmentTypeAttributePage";
import StandardIssuePage from "./components/basicdata/standardIssue/StandardIssuePage";
import EquipmentGroupPage from "./components/basicdata/equipmentgroup/EquipmentGroupPage";
import CountyAuthorityPage from "./components/basicdata/countyauthority/CountyAuthorityPage";
import ReportSendPage from "./components/report/send/ReportSendPage";
import AdminTestPage from "./components/admin/test/AdminTestPage";
import queryString from "query-string";
import EquipmentTypeRestrictionPage from "./components/basicdata/equipmentTypeRestrictions/EquipmentTypeRestrictionPage";
import AuthorityReportList from "./components/authority/AuthorityReportList";
import useAuth from "./hooks/useAuth";
import ConfigPage from "./components/admin/config/ConfigPage";

function App() {
    const { auth } = useAuth();
    const location = useLocation();
    const queryParams = queryString.parse(location.search);
    return (
        <Routes>
            <Route path="/" element={<Layout />}>
                {/* public routes*/}
                <Route path="login" element={<Login />} />
                <Route path="forgotpassword" element={<ForgotPassword />} />
                <Route path="unauthorized" element={<Unauthorized />} />
                <Route path="passwordreset" element={<PasswordReset />} />

                {/* protected routes*/}
                <Route element={<PersistLogin />}>
                    <Route
                        element={
                            <RequireAuth
                                allowedRoles={[
                                    ROLE.INSPECTOR,
                                    ROLE.ADMIN,
                                    ROLE.SUPERADMIN,
                                    ROLE.AUTHORITY,
                                ]}
                                queryParams={queryParams}
                            />
                        }
                    >
                        {auth?.role == ROLE.AUTHORITY.code ? (
                            <Route path="/" element={<AuthorityReportList />} />
                        ) : (
                            <Route exact path="/" element={<Dashboard />} />
                        )}

                        <Route
                            element={
                                <RequireAuth
                                    allowedRoles={[
                                        ROLE.INSPECTOR,
                                        ROLE.ADMIN,
                                        ROLE.SUPERADMIN,
                                    ]}
                                    queryParams={queryParams}
                                />
                            }
                        >
                            <Route
                                path="report/playground/send"
                                element={<ReportSendPage />}
                            />
                            <Route
                                path="report/:reportType/trellocardid/:trelloCardId"
                                element={<PlaygroundReport />}
                            />
                            <Route
                                path="report/:reportType/list/:return?"
                                element={<ReportListPage />}
                            />
                            <Route
                                path="report/:reportType/:reportId/:option?"
                                element={<PlaygroundReport />}
                            />
                            <Route
                                path="report/:reportType"
                                element={<PlaygroundReport />}
                            />
                            <Route path="standard" element={<StandardPage />} />
                            <Route path="operator" element={<OperatorPage />} />
                            <Route path="location" element={<LocationPage />} />
                            <Route
                                path="countyauthority"
                                element={<CountyAuthorityPage />}
                            />
                            <Route
                                path="inspectiontype"
                                element={<InspectionTypePage />}
                            />
                            <Route
                                path="equipment"
                                element={<EquipmentPage />}
                            />
                            <Route
                                path="standardissue"
                                element={<StandardIssuePage />}
                            />
                            <Route
                                path="equipmenttype"
                                element={<EquipmentTypePage />}
                            />
                            <Route
                                path="equipmentcategory"
                                element={<EquipmentCategoryPage />}
                            />
                            <Route
                                path="equipmentgroup"
                                element={<EquipmentGroupPage />}
                            />
                            <Route
                                path="manufacturer"
                                element={<ManufacturerPage />}
                            />
                            <Route
                                path="certificateissuer"
                                element={<CertificateIssuerPage />}
                            />
                            <Route
                                path="equipmenttypeattribute"
                                element={<EquipmentTypeAttributePage />}
                            />
                            <Route
                                path="equipmenttyperestriction"
                                element={<EquipmentTypeRestrictionPage />}
                            />
                            <Route
                                path="image/dropbox-code"
                                element={<DropBoxCode />}
                            />
                        </Route>
                    </Route>

                    <Route
                        element={
                            <RequireAuth
                                allowedRoles={[ROLE.SUPERADMIN, ROLE.ADMIN]}
                            />
                        }
                    >
                        <Route path="admin" element={<AdminPage />} />
                        <Route path="admin/users" element={<AdminUserPage />} />
                        <Route path="admin/config" element={<ConfigPage />} />
                        <Route
                            path="admin/templates"
                            element={<AdminTemplatePage />}
                        />
                        <Route
                            path="admin/dropboxauth"
                            element={<DropBoxAuth />}
                        />
                    </Route>
                    <Route
                        element={
                            <RequireAuth allowedRoles={[ROLE.SUPERADMIN]} />
                        }
                    >
                        <Route
                            path="admin/testpage"
                            element={<AdminTestPage />}
                        />
                    </Route>
                </Route>
            </Route>
        </Routes>
    );
}

export default App;
