import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import useEquipmentTypeRestrictionContext from "./useEquipmentTypeRestrictionContext";
import { useQuickFilterWithFiltering } from "../../common/DiacriticSearch";
import { Button } from "@mui/material";

function EquipmentTypeRestrictionTable() {
    const {
        equipmentTypeRestrictions,
        download,
        setEditedId,
        showTableLoading,
    } = useEquipmentTypeRestrictionContext();

    const columns = [
        { field: "name", headerName: "Név", minWidth: 70, flex: 0.5 },
        { field: "value", headerName: "Érték", minWidth: 70, flex: 0.5 },
        {
            field: "hasLogo",
            headerName: "Piktogram",
            minWidth: 130,
            flex: 0.1,
            renderCell: (params) =>
                params.value ? (
                    <strong>
                        <Button
                            size="small"
                            onClick={download}
                            tabIndex={params.hasFocus ? 0 : -1}
                        >
                            Letöltés
                        </Button>
                    </strong>
                ) : (
                    "Nincs"
                ),
        },
    ];

    function selectionModelChanged(ids) {
        const id = ids.length !== 0 ? ids[0] : null;
        rowSelected(id);
    }

    function rowSelected(id) {
        setEditedId(id);
    }

    // Use the custom quick filter hook for multiple fields
    const { filteredRows, quickFilterProps } = useQuickFilterWithFiltering(
        equipmentTypeRestrictions,
        ["name"]
    );

    return (
        <div style={{ height: 500, width: "100%" }}>
            <DataGrid
                rows={filteredRows}
                loading={showTableLoading}
                columns={columns}
                pageSize={10}
                rowsPerPageOptions={[10]}
                disableColumnSelector
                components={{ Toolbar: GridToolbar }}
                componentsProps={{
                    toolbar: {
                        showQuickFilter: true,
                        quickFilterProps,
                    },
                }}
                onSelectionModelChange={(ids) => selectionModelChanged(ids)}
            />
        </div>
    );
}

export default EquipmentTypeRestrictionTable;
