import { Box, Step, StepLabel, Stepper } from "@mui/material";
import { ReportType } from "../../utils/reportType";
import PageTitleWithBackButton from "../common/PageTitleWithBackButton";
import ReportBaseDataPage from "./basedata/ReportBaseDataPage";
import ReportLocationPage from "./location/ReportLocationPage";
import useReportContext from "./useReportContext";
import { useParams } from "react-router-dom";
import ReportSelectCoverImage from "./coverimage/selectCoverImage/ReportSelectCoverImage";
import PlaygroundReportEquipmentPage from "./equipment/PlaygroundReportEquipmentPage";
import PlaygroundReportGeneratePage from "./generate/PlaygroundReportGeneratePage";
import PlaygroundReportEquipmentsPage from "./equipments/PlaygroundReportEquipmentsPage";
import { ReportStep } from "./ReportStep";
import EntertainmentReportEquipmentPage from "./equipments/entertainment/EntertainmentReportEquipmentsPage";

const playgroundSteps = [
    ReportStep.BaseData,
    ReportStep.CoverImage,
    ReportStep.Location,
    ReportStep.Equipments,
    ReportStep.EquipmentDetails,
    ReportStep.Generate,
];

const sportEquipmentSteps = [
    ReportStep.BaseData,
    ReportStep.CoverImage,
    ReportStep.Location,
    ReportStep.Equipments,
    ReportStep.EquipmentDetails,
    ReportStep.Generate,
];

const enternainmentSteps = [
    ReportStep.BaseData,
    ReportStep.CoverImage,
    ReportStep.Location,
    ReportStep.EntertainmentEquipment,
    ReportStep.EquipmentDetails,
    ReportStep.Generate,
];

const PlaygroundReportContent = () => {
    let params = useParams();
    const isReturn = params.option === "return";
    const { report, activeStep, reportConfig } = useReportContext();

    const getSteps = (report) => {
        switch (report.baseData.reportType.code) {
            case ReportType.PLAYGROUND.code:
                return playgroundSteps;
            case ReportType.SPORT_EQUIPMENT.code:
                return sportEquipmentSteps;
            case ReportType.ENTERTAINMENT.code:
                return enternainmentSteps;
            default:
                return [];
        }
    };

    function renderContent(param) {
        switch (param) {
            case ReportStep.BaseData: {
                return <ReportBaseDataPage />;
            }
            case ReportStep.CoverImage: {
                return <ReportSelectCoverImage />;
            }
            case ReportStep.Location: {
                return <ReportLocationPage />;
            }
            case ReportStep.Equipments: {
                return <PlaygroundReportEquipmentsPage />;
            }
            case ReportStep.EntertainmentEquipment: {
                return <EntertainmentReportEquipmentPage />;
            }
            case ReportStep.EquipmentDetails: {
                return <PlaygroundReportEquipmentPage />;
            }
            case ReportStep.Generate: {
                return <PlaygroundReportGeneratePage />;
            }
            default:
                return <Box></Box>;
        }
    }

    function getTitle(report) {
        let title = "";
        let reportNumberWithoutPrefix;
        let reportNumber = "";
        if (report.baseData.number != null && report.baseData.year != null)
            reportNumberWithoutPrefix = `${report.baseData.number}/${report.baseData.year}`;
        if (reportNumberWithoutPrefix)
            reportNumber = `${reportConfig?.reportNamePrefix}${reportNumberWithoutPrefix}`;
        switch (report.baseData.reportType.code) {
            case ReportType.PLAYGROUND.code:
                title = `Játszótéri eszköz`;
                break;
            case ReportType.SPORT_EQUIPMENT.code:
                title = `Sporteszköz`;
                break;
            case ReportType.ENTERTAINMENT.code:
                title = `Szórakoztató eszköz`;
                break;
        }
        title = `${title} ${reportNumber}`;
        return title;
    }

    const steps = getSteps(report);

    return (
        <Box sx={{ mt: 2 }}>
            <PageTitleWithBackButton title={getTitle(report)} />
            <Stepper activeStep={activeStep} sx={{ my: 2 }}>
                {steps.map((step, index) => {
                    const stepProps = {};
                    const labelProps = {};
                    return (
                        <Step key={step.title} {...stepProps}>
                            <StepLabel {...labelProps}>{step.title}</StepLabel>
                        </Step>
                    );
                })}
            </Stepper>
            <Box sx={{ flexGrow: 1, my: 8 }}>
                {renderContent(steps[activeStep])}
            </Box>
        </Box>
    );
};

export default PlaygroundReportContent;
