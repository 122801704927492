import { createContext, useState, useLayoutEffect, useEffect } from "react";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import useFullScreenProgressBarContext from "../../common/fullscreenprogressbar/useFullScreenProgressBarContext";
import useGeneralErrorContext from "../../common/generalerrorsnackbar/useGeneralErrorContext";
import { EQUIPMENT_TYPE_RESTRICTION_URL } from "../../../api/Url";
import { useEquipmentTypeRestrictionApi } from "../../../api/equipmentTypeRestrictionApi";

const emptyEquipmentTypeRestriction = {
    id: null,
    name: null,
    value: null,
    logo: null,
};

const EquipmentTypeRestrictionContext = createContext({});

export const EquipmentTypeRestrictionProvider = ({ children }) => {
    const { downloadLogo } = useEquipmentTypeRestrictionApi();
    const { setShowFullScreenProgressBar } = useFullScreenProgressBarContext();
    const { handleGeneralError } = useGeneralErrorContext();
    const axiosPrivate = useAxiosPrivate();
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
    const [showTableLoading, setShowTableLoading] = useState(false);
    const [showDialog, setShowDialog] = useState(false);

    const [editedId, setEditedId] = useState(null);

    const [equipmentTypes, setEquipmentTypes] = useState([]);
    const [selectedEquipmentType, setSelectedEquipmentType] = useState(null);

    const [equipmentTypeRestrictions, setEquipmentTypeRestrictions] = useState(
        []
    );

    const [equipmentTypeRestriction, setEquipmentTypeRestriction] = useState({
        emptyEquipmentTypeRestriction,
    });

    const hasError =
        equipmentTypeRestriction.name == null ||
        equipmentTypeRestriction.value == null;

    function processData(name, value) {
        switch (name) {
            case "name": {
                break;
            }
            case "type":
                setSelectedEquipmentType(value);
                break;
            default:
                break;
        }
    }

    const handleChange = (e) => {
        const type = e.target.type;

        const name = e.target.name;

        let value;
        switch (type) {
            case "checkbox": {
                value = e.target.checked;
                break;
            }
            case "autocomplete": {
                value = e.target.selectedOption;
                break;
            }
            case "file": {
                value = e.target.files[0];
                break;
            }
            default: {
                value = e.target.value;
                break;
            }
        }

        processData(e.target.name, value);

        setEquipmentTypeRestriction((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    useEffect(() => {
        fetchEquipmentTypes();
    }, []);

    useLayoutEffect(() => {
        if (editedId != null) {
            const equipmentTypesRestriction = equipmentTypeRestrictions.find(
                (equipmentTypesRestriction) =>
                    equipmentTypesRestriction.id === editedId
            );
            setEquipmentTypeRestriction(equipmentTypesRestriction);
        } else {
            setEquipmentTypeRestriction(emptyEquipmentTypeRestriction);
        }
    }, [editedId, setEditedId]);

    useEffect(() => {
        if (selectedEquipmentType != null) {
            fetchEquipmentTypeRestrictionsByType();
        } else {
            setEquipmentTypeRestrictions([]);
        }
    }, [selectedEquipmentType, setSelectedEquipmentType]);

    const fetchEquipmentTypes = async (e) => {
        try {
            const response = await axiosPrivate.get(`equipmenttype/list`, {
                withCredentials: true,
            });
            setEquipmentTypes(response.data.equipmentTypes);
        } catch (error) {
            handleGeneralError(error);
        }
    };

    function download() {
        downloadLogo(editedId);
    }

    const fetchEquipmentTypeRestrictionsByType = async (e) => {
        setShowTableLoading(true);
        try {
            const response = await axiosPrivate.get(
                `${EQUIPMENT_TYPE_RESTRICTION_URL}/list/${selectedEquipmentType.id}`,
                {
                    withCredentials: true,
                }
            );
            setEquipmentTypeRestrictions(
                response.data.equipmentTypeRestrictions
            );
        } catch (error) {
            handleGeneralError(error);
        } finally {
            setShowTableLoading(false);
        }
    };

    const saveEquipmentTypeRestriction = async () => {
        setShowFullScreenProgressBar(true);
        try {
            const formData = new FormData();
            formData.append("File", equipmentTypeRestriction.logo);
            if (equipmentTypeRestriction?.id) {
                formData.append("id", equipmentTypeRestriction?.id);
            }
            formData.append("name", equipmentTypeRestriction.name);
            formData.append("type", selectedEquipmentType.id);
            formData.append("value", equipmentTypeRestriction.value);

            await axiosPrivate.post(
                `${EQUIPMENT_TYPE_RESTRICTION_URL}`,
                formData,
                {
                    headers: { "Content-Type": "application/json" },
                    withCredentials: true,
                }
            );

            setEquipmentTypeRestriction(emptyEquipmentTypeRestriction);
            fetchEquipmentTypeRestrictionsByType();
        } catch (error) {
            handleGeneralError(error);
        } finally {
            setShowFullScreenProgressBar(false);
        }
    };

    const deleteEquipmentTypeRestriction = async () => {
        setShowFullScreenProgressBar(true);
        try {
            await axiosPrivate.delete(
                `${EQUIPMENT_TYPE_RESTRICTION_URL}/${equipmentTypeRestriction.id}`,
                {
                    headers: { "Content-Type": "application/json" },
                    withCredentials: true,
                }
            );

            fetchEquipmentTypeRestrictionsByType();
        } catch (error) {
            handleGeneralError(error);
        } finally {
            setShowFullScreenProgressBar(false);
        }
    };

    const showTableEditButtons = editedId === null ? false : true;

    return (
        <EquipmentTypeRestrictionContext.Provider
            value={{
                equipmentTypes,
                equipmentTypeRestrictions,
                showDialog,
                setShowDialog,
                showDeleteConfirm,
                setShowDeleteConfirm,
                editedId,
                setEditedId,
                hasError,
                handleChange,
                download,
                selectedEquipmentType,
                equipmentTypeRestriction,
                saveEquipmentTypeRestriction,
                deleteEquipmentTypeRestriction,
                showTableEditButtons,
                showTableLoading,
            }}
        >
            {children}
        </EquipmentTypeRestrictionContext.Provider>
    );
};

export default EquipmentTypeRestrictionContext;
