import { Button, Stack } from "@mui/material";
import { Box } from "@mui/system";
import ConfigEditDialog from "./ConfigEditDialog";
import useConfigContext from "./useConfigContext";
import EditIcon from "@mui/icons-material/Edit";
import PageTitleWithBackButton from "../../common/PageTitleWithBackButton";
import ConfigTable from "./ConfigTable";

function ConfigPageContent() {
    const { setShowDialog, showTableEditButtons } = useConfigContext();

    const handleClickOpen = (e) => {
        switch (e.target.name) {
            case "edit": {
                setShowDialog(true);
                break;
            }
            default:
                break;
        }
    };

    return (
        <Box sx={{ mt: 2 }}>
            <PageTitleWithBackButton title="Beállítások" />
            <Stack direction="row" spacing={2} m={2}>
                {showTableEditButtons && (
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Button
                            sx={{ mx: 1 }}
                            variant="outlined"
                            name="edit"
                            startIcon={<EditIcon />}
                            onClick={handleClickOpen}
                        >
                            Szerkesztés
                        </Button>
                    </Box>
                )}
            </Stack>
            <ConfigTable />
            <ConfigEditDialog />
        </Box>
    );
}

export default ConfigPageContent;
