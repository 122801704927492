import { createContext, useState, useEffect } from "react";
import { useEquipmentCategoryApi } from "../../../api/equipmentCategoryApi";
import { useInspectionTypeApi } from "../../../api/inspectionTypeApi";
import { useConfigApi } from "../../../api/configApi";

const ConfigContext = createContext({});

export const ConfigProvider = ({ children }) => {
    const { getConfigs, saveConfig } = useConfigApi();
    const [showDialog, setShowDialog] = useState(false);
    const [showTableEditButtons, setShowTableEditButtons] = useState(false);
    const [configs, setConfigs] = useState([]);
    const [data, setData] = useState(null);

    const handleChange = (e) => {
        const type = e.target.type;

        const name = e.target.name;

        let value;
        switch (type) {
            case "checkbox": {
                value = e.target.checked;
                break;
            }
            case "autocomplete": {
                value = e.target.selectedOption;
                break;
            }
            case "file": {
                value = e.target.files[0];
                break;
            }
            default: {
                value = e.target.value;
                break;
            }
        }

        setData((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    useEffect(() => {
        fetchConfigs();
    }, []);

    function setSelectedConfig(key) {
        if (key != null) {
            const config = configs.find((c) => c.key === key);
            setData(config);
            setShowTableEditButtons(true);
        } else {
            setData(null);
        }
    }

    function fetchConfigs() {
        getConfigs((response) => {
            setConfigs(response.data.configs);
        });
    }

    function save() {
        saveConfig(data, (response) => {
            setData(null);
            fetchConfigs();
        });
    }

    return (
        <ConfigContext.Provider
            value={{
                data,
                configs,
                showDialog,
                setShowDialog,
                handleChange,
                save,
                setSelectedConfig,
                showTableEditButtons,
            }}
        >
            {children}
        </ConfigContext.Provider>
    );
};

export default ConfigContext;
