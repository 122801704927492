export const EQUIPMENT_URL = "/equipment";
export const EQUIPMENT_TYPE_URL = "/equipmenttype";
export const EQUIPMENT_CATEGORY_URL = "/equipmentcategory";
export const EQUIPMENT_GROUP_URL = "/equipmentgroup";
export const COUNTY_AUTHORITY_URL = "/countyauthority";
export const LOCATION_URL = "/location";
export const TEMPLATE_URL = "/template";
export const USER_URL = "/user";
export const CERTIFICATE_ISSUER_URL = "/certificateissuer";
export const LOGIN_URL = "user/login";
export const MANUFACTURER_URL = "/manufacturer";
export const OPERATOR_URL = "/operator";
export const STANDARD_URL = "/standard";
export const STATISTICS_URL = "/statistics";
export const STANDARDISSUE_URL = "/standardissue";
export const REPORT_URL = "/report";
export const IMAGE_URL = "/image";
export const INSPECTION_TYPE_URL = "/inspectiontype";
export const EQUIPMENT_TYPE_ATTRIBUTE_URL = "/equipmenttypeattribute";
export const EQUIPMENT_TYPE_RESTRICTION_URL = "/equipmenttyperestriction";
export const GENERATED_REPORT = "/generatedreport";
export const CONFIG_URL = "/config";
export const TEST_URL = "/test";
