import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import useConfigContext from "./useConfigContext";
import { FormControlLabel, Typography } from "@mui/material";

function ConfigEditDialog() {
    const { data, handleChange, showDialog, setShowDialog, save } =
        useConfigContext();

    const handleClose = (event, reason) => {
        if (reason && reason === "backdropClick") return;

        switch (event.target.name) {
            case "save": {
                save();
                break;
            }
            default:
                break;
        }
        setShowDialog(false);
    };

    const title = "Beállítás szerkesztés";

    return (
        data && (
            <Dialog
                disableEscapeKeyDown
                fullWidth
                maxWidth="md"
                open={showDialog}
                onClose={handleClose}
            >
                <DialogTitle>{title}</DialogTitle>
                <DialogContent>
                    <FormControlLabel
                        sx={{ mb: 2 }}
                        control={
                            <Typography sx={{ ml: 2 }}>
                                {data.description}
                            </Typography>
                        }
                        label="Leírás:"
                        labelPlacement="start"
                    ></FormControlLabel>
                    <TextField
                        name="value"
                        label="Érték"
                        multiline
                        rows={10} // Number of visible rows
                        placeholder="Érték"
                        variant="outlined"
                        fullWidth
                        value={data.value}
                        onChange={handleChange}
                    />
                </DialogContent>
                <DialogActions>
                    <Button name="cancel" onClick={handleClose}>
                        Mégse
                    </Button>
                    <Button name="save" onClick={handleClose}>
                        Mentés
                    </Button>
                </DialogActions>
            </Dialog>
        )
    );
}

export default ConfigEditDialog;
